<template>
    <div class="container">

        <div class="md:flex md:space-x-6 lg:mx-6">
            <div class="w-full flex-shirink-0 containerSticky">
                <div class="card p-4 mb-5">
                    <h1 class="block text-lg font-bold">Pencarian</h1>
                    <div class="mt-3">
                        <b-form-group v-slot="{ ariaDescribedby }">
                            <b-form-radio v-model="filter" :aria-describedby="ariaDescribedby" value="">Semua</b-form-radio>
                            <b-form-radio v-model="filter" :aria-describedby="ariaDescribedby" value="discussion">Discussion</b-form-radio>
                            <b-form-radio v-model="filter" :aria-describedby="ariaDescribedby" value="timeline">Timeline</b-form-radio>
                            <b-form-radio v-model="filter" :aria-describedby="ariaDescribedby" value="news">News</b-form-radio>
                            <b-form-radio v-model="filter" :aria-describedby="ariaDescribedby" value="resource">Resources</b-form-radio>
                            <b-form-radio v-model="filter" :aria-describedby="ariaDescribedby" value="career">Career</b-form-radio>
                            <b-form-radio v-model="filter" :aria-describedby="ariaDescribedby" value="mbkm">MBKM</b-form-radio>
                            <b-form-radio v-model="filter" :aria-describedby="ariaDescribedby" value="group">Group</b-form-radio>
                            <b-form-radio v-model="filter" :aria-describedby="ariaDescribedby" value="user">User</b-form-radio>
                        </b-form-group>
                    </div>
                </div>
            </div>
            <div class="space-y-5 flex-shrink-0 md:w-9/12 search-result">
                <div>
                    <p class="m-0">Menampilkan hasil pencarian untuk <strong>{{ $route.params.q }}</strong></p>
                    <div v-if="(filter === 'discussion' || filter === '') && result.discussions && result.discussions.length > 0" class="card p-4 mb-5 mt-2">
                        <p class="font-semibold mb-3">Discussion</p>
                        <div v-for="item in result.discussions" :key="item.id">
                            <div class="flex mb-4">
                                <div>
                                    <router-link :to="{ name: 'GroupDetailDiscussion', params: { slug: item.slug } }">
                                        <h5 class="font-semibold text-md truncate">{{ item.title }}</h5>
                                    </router-link>
                                    <div>
                                        <p class="subtitle">Posted By: {{ item.author.fullname }} {{ formatDateFromNow(item.created_at) }}</p>
                                        <p v-if="item.topics && item.topics.length" class="subtitle">Topics: {{ item.topics.map(i => i.title).join(', ') }}</p>
                                    </div>
                                    <p>{{ item.excerpt }}</p>
                                </div>
                            </div>
                        </div>
                        <div v-if="result.discussions && result.discussions.length < 1">
                            <p>No discussion found</p>
                        </div>
                    </div>
                    <div v-if="(filter === 'timeline' || filter === '') && result.timelines && result.timelines.length > 0" class="card p-4 mb-5 mt-2">
                        <p class="font-semibold mb-3">Timeline</p>
                        <div v-for="item in result.timelines" :key="item.id" class="mb-4">
                            <router-link :to="{ name: 'GroupDetailTimeline', params: { slug: item.id, code: item.group_slug } }">
                                <div class="flex">
                                    <div>
                                        <p>
                                            <a href="#" class="text-black dark:text-gray-100 font-semibold">{{ item.author.fullname }}</a>
                                            <span class="subtitle ml-2">{{ formatDateFromNow(item.created_at) }}</span>
                                        </p>
                                        <p>{{ item.excerpt }}</p>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                        <div v-if="result.timelines && result.timelines.length < 1">
                            <p>No timeline found</p>
                        </div>
                    </div>
                    <div v-if="(filter === 'news' || filter === '') && result.news && result.news.length > 0" class="card p-4 mb-5 mt-2">
                        <p class="font-semibold mb-4">News</p>
                        <div v-for="item in result.news" :key="item.id">
                            <router-link :to="{ name: 'GroupDetailTimeline', params: { slug: item.id, code: item.group_slug } }">
                                <div class="flex mb-3">
                                    <div class="mr-2 rounded-sm">
                                        <img :src="item.images" width="150" class="rounded-sm">
                                    </div>
                                    <div>
                                        <h5 class="font-semibold text-lg truncate">{{ item.title }}</h5>
                                        <p class="subtitle">{{ formatDate(item.created_at) }}</p>
                                        <p>{{ item.excerpt }}</p>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                        <div v-if="result.news && result.news.length < 1">
                            <p>No news found</p>
                        </div>
                    </div>
                    <div v-if="(filter === 'group' || filter === '') && result.groups && result.groups.length > 0"  class="card p-4 mb-5 mt-2">
                        <p class="font-semibold mb-4">Group</p>
                        <div v-for="item in result.groups" :key="item.id">
                            <router-link :to="{ name: 'GroupDetail', params: {code: item.slug } }">
                                <div class="flex mb-3">
                                    <div class="mr-2 rounded-sm">
                                        <img :src="item.preview" width="150" class="rounded-sm">
                                    </div>
                                    <div>
                                        <h5 class="font-semibold text-md">{{ item.title }}</h5>
                                        <div class="flex items-center flex-wrap space-x-1 text-sm text-gray-500 capitalize">
                                            <a href="#"> <span>{{ item.total_member }} Members</span> </a>
                                            <a href="#"> <span>{{ item.total_post }} Total Post</span> </a>
                                        </div>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                        <div v-if="result.groups && result.groups.length < 1">
                            <p>No group found</p>
                        </div>
                    </div>
                    <div v-if="(filter === 'event' || filter === '') && result.events && result.events.length > 0"  class="card p-4 mb-5 mt-2">
                        <p class="font-semibold mb-4">Event</p>
                        <div v-for="item in result.events" :key="item.id">
                            <router-link :to="{ name: 'GroupDetail', params: {code: item.slug } }">
                                <div class="flex mb-3">
                                    <div class="mr-2 rounded-sm">
                                        <img :src="item.preview" width="150" class="rounded-sm">
                                    </div>
                                    <div>
                                        <h5 class="font-semibold text-md">{{ item.title }}</h5>
                                        <div class="flex items-center flex-wrap space-x-1 text-sm text-gray-500 capitalize">
                                            <a href="#"> <span>{{ item.total_member }} Members</span> </a>
                                            <a href="#"> <span>{{ item.total_post }} Total Post</span> </a>
                                        </div>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                        <div v-if="result.events && result.events.length < 1">
                            <p>No event found</p>
                        </div>
                    </div>
                    <div v-if="(filter === 'resource' || filter === '') && result.resources && result.resources.length > 0" class="card p-4 mb-5 mt-2">
                        <p class="font-semibold mb-4">Resource</p>
                        <div v-for="item in result.resources" :key="item.id">
                            <router-link :to="{ name: 'DetailResource', params: {code: item.slug } }">
                                <div class="flex mb-3">
                                    <div class="mr-2 rounded-sm">
                                        <img :src="item.images" width="150" class="rounded-sm">
                                    </div>
                                    <div>
                                        <h5 class="font-semibold text-md">{{ item.title }}</h5>
                                        <p class="subtitle">{{ formatDate(item.created_at) }}</p>
                                        <p>{{ item.excerpt }}</p>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                        <div v-if="result.resources && result.resources.length < 1">
                            <p>No resource found</p>
                        </div>
                    </div>
                    <div v-if="(filter === 'career' || filter === '') && result.careers && result.careers.length > 0" class="card p-4 mb-5 mt-2">
                        <p class="font-semibold mb-4">Career</p>
                        <div v-for="item in result.careers" :key="item.id">
                            <router-link :to="{ name: 'DetailJob', params: { code: item.slug } }">
                                <div class="flex mb-3">
                                    <div class="mr-2 rounded-sm">
                                        <img :src="item.images" width="150" class="rounded-sm">
                                    </div>
                                    <div>
                                        <h5 class="font-semibold text-md">{{ item.title }}</h5>
                                        <b-badge class="bg-theme">{{ item.job_type }}</b-badge>
                                        <p>{{ item.excerpt }}</p>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                        <div v-if="result.careers && result.careers.length < 1">
                            <p>No career found</p>
                        </div>
                    </div>
                    <div v-if="(filter === 'mbkm' || filter === '') && result.mbkm && result.mbkm.length > 0" class="card p-4 mb-5 mt-2">
                        <p class="font-semibold mb-4">MBKM</p>
                        <div v-for="item in result.mbkm" :key="item.id">
                            <router-link :to="{ name: 'DetailMBKM', params: {code: item.slug } }">
                                <div class="flex mb-3">
                                    <div class="mr-2 rounded-sm">
                                        <img :src="item.images" width="150" class="rounded-sm">
                                    </div>
                                    <div>
                                        <h5 class="font-semibold text-md">{{ item.title }}</h5>
                                        <p>{{ item.excerpt }}</p>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                        <div v-if="result.mbkm && result.mbkm.length < 1">
                            <p>No MBKM found</p>
                        </div>
                    </div>
                    <div v-if="(filter === 'user' || filter === '') && result.users && result.users.length > 0" class="card p-4 mb-5 mt-2">
                        <p class="font-semibold mb-4">Users</p>
                        <div v-for="item in result.users" :key="item.id">
                            <router-link :to="{ name: 'Profile', params: { code: item.username } }">
                                <div class="flex mb-3">
                                    <div class="mr-2 rounded-sm">
                                        <img :src="item.images !== '' ? item.images : require('@/assets/images/avatars/avatar-5.jpg')" class="w-10 h-10 rounded-full mb-2">
                                    </div>
                                    <div>
                                        <h5 class="font-semibold text-md">{{ item.fullname }}</h5>
                                        <p>{{ item.email }}</p>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                        <div v-if="result.users && result.users.length < 1">
                            <p>No user found</p>
                        </div>
                    </div>
                    <!-- <div class="mt-20 d-none">
                        <empty>
                            <p class="pt-2">Search result is empty</p>
                        </empty>
                    </div> -->
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'

    export default {
        components: { },
        computed: mapState('search', ['loading', 'result']),
        data () {
            return {
                filter: ''
            }
        },
        methods: {
            ...mapActions('search', ['search']),
            getResult ({ q }) {
                this.search({
                    params: { q }
                })
                    .then(() => {
                        this.$forceUpdate()
                    })
            },
            fetchData () {
                this.getResult({
                    q: this.$route.params.q
                })
            }
        },
        watch: {
            $route: 'fetchData'
        },
        created () {
            this.fetchData()
            this.$eventHub.on('GLOBAL_SEARCH', this.getResult)
        }
    }
</script>
